import { Link } from "react-router-dom";
import { useShoppingCart } from "use-shopping-cart"
import { useEffect } from "react";

const Success = () => {
  const {  clearCart } = useShoppingCart()

  
  
  return (
   <section className="py-72">
    <div className="container mx-auto">
      <h3 className="text-center mb-4">Your payment was successfull! Thank you!</h3>
      <Link to='/'>
      <button className="btn btn-primary mx-auto" onClick={clearCart}>Clear your Basket and go Back to Home Page</button>
      </Link>
    </div>
   </section>
  )
}

export default Success