import { Link } from "react-router-dom"
import { useLocation } from 'react-router-dom';

interface NavProps {
  containerStyles: string;
}

const Nav = ({ containerStyles }: NavProps) => {
  const location = useLocation();
const links = [
  { to: '/', text: 'Home' },
  { to: '/allproducts', text:'Products'}
]


  return (
    <nav className={`${containerStyles}`}>
     
        {links.map((link, index) => (
        location.pathname !== link.to && (
          <div key={index}>
            <Link to={link.to}>{link.text}</Link>
          </div>
        )
      ))}
      
    </nav>
  )
}

export default Nav