import { Link } from "react-router-dom"

const Error = () => {
  return (
    <section className="py-72">
    <div className="container mx-auto">
      <h3 className="text-center mb-4">Something went wrong!</h3>
      <Link to='/'>
      <button className="btn btn-accent mx-auto"> Back to Home Page</button>
      </Link>
    </div>
   </section>
  )
}

export default Error