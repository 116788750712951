import React from 'react'
import { useShoppingCart } from 'use-shopping-cart'
import { AddToCartBtnProps } from '../interface/trackerInterface'
import { useToast } from '../@/components/ui/use-toast'


const AddToCartBtn: React.FC<AddToCartBtnProps> = ({ 
  _id, id, btnStyles, text, icon, currency, title, description, img, price, stripeproductid
}) => {
  const {addItem, incrementItem, cartDetails} = useShoppingCart();
  const { toast } = useToast()

  const product = {
    sku: stripeproductid,
    name: title,
    description: description,
    img: img,
    price: price,
    currency: currency,
    stripeproductid: stripeproductid
  }

  const handleAddToCart = () => {
    if (cartDetails?.[product.sku]) {
      incrementItem(product.sku); 
    } else {
      addItem(product);
    }
    toast({
      title:  `${title} has been added to your cart`,
      duration: 5000,
      //isClosable: true,
    })
  }
 

  return (
    <button className={`${btnStyles}`} onClick={handleAddToCart}>
      <div>{text}</div>
      <div>{icon} </div>
    </button>
  )
}

export default AddToCartBtn