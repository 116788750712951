import { Link } from "react-router-dom"

const Hero = () => {
  return (
    <section className="py-24 md:py-0 md:h-[820px] relative overflow-hidden bg-primary/5" >
    <div className="container mx-auto">
    <div className="flex justify-between items-center flex-col-reverse md:flex-row" >
   
    <div className="w-full xl:max-w-[580px] md:h-[820px] flex flex-col justify-center items-start xl:flex">
      <h1 className="text-center xl:text-center xl:text-left mb-6">
        Track <span>Your World, </span>Secure Your Future
        </h1>
        <p className="mb-10 text-lg max-w-[508px] mx-auto text-center xl:text-left xl:0">
        Empowering your journey with real-time precision and unbeatable security, ensuring every asset is safeguarded every step of the way
        </p>
        <div className="flex items-center gap-4 mx-auto xl:mx-0">
          <Link to='/allproducts' className="mx-auto md:mx:mx-0">
          <button className="btn btn-primary">
            Shop Now
          </button>
          </Link>
          <Link to='/allproducts' className="mx-auto md:mx:mx-0">
          <button className="btn btn-accent">
            Our Products
          </button>
          </Link>
        </div>
      </div>
      <div className=" xl:flex ">

    <img src="https://res.cloudinary.com/dytsb4ocs/image/upload/v1718804665/iot/ljulpi0ri3yxyie3nwai.webp" alt=""
      width={765} height={480} 
    />
      </div>
      </div>
    </div>
    </section>
  )
}

export default Hero