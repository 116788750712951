import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchProducts } from '../api/controllers';
import { Link } from 'react-router-dom';
import { Tracker} from '../interface/trackerInterface'
import AddToCartBtn from './AddToCartBtn';
import {
    Map,
    
    Clock,
    PackageCheck,
    RefreshCw,
    ChevronLeft,
  } from 'lucide-react';

  
  const ProdDetail = () => {
    const { title } = useParams();
    const [product, setProduct] = useState<Tracker | null>(null);
  
    useEffect(() => {
      const fetchData = async () => {
        const products = await fetchProducts();
        const foundProduct = products.find((item: Tracker) => item.title === title);
        setProduct(foundProduct || null);
      };
  
      fetchData();
    }, [title]);
  
    if (!product) {
      return <div>Product not found</div>
    }
  
      return (
        <section className='pt-24 pb-32'>
        <div className="container mx-auto  ">
         <div className='flex flex-col xl:flex-row gap-14  '>
         <div className='bg-primary/5 flex justify-center items-center xl:flex-1 h-[460px] xl:w-[700px] xl:h-[540px]'>
           
           <img src={product.img} alt={product.title} width={240} height={200}  />
         
         </div>
         <div className=' flex-1 flex flex-col justify-center items-start gap-10'>
           <Link to ='/' className='flex items-center gap-2 font-semibold'>
           <ChevronLeft size={20} />
           Back to Home
           </Link>
           <div className='flex flex-col gap-6 items-start'>
             <div>
               <h3>{product.title} </h3>
               <p className='text-lg font-semibold'>${product.price}</p>
             </div>
             <p>{product.description}</p>
             <AddToCartBtn 
  id={product.id}
  _id={product._id}
  currency={product.currency}
  title={product.title}
  description={product.description}
  img={product.img}
  price={product.price}
  stripeproductid={product.stripeproductid}
  text={'Add to cart'} 
  btnStyles={'btn btn-accent'} 
  icon 
/>
           </div>
             {/*info */}
           <div className=' flex flex-col gap-3'>
             <div className='flex gap-2'>
               <PackageCheck size={20} className='text-accent' />
               <p>Free shipping on orders over $300</p>
             </div>
           <div className='flex gap-2'>
               <RefreshCw size={20} className='text-accent' />
               <p>Free return for 7 days</p>
             </div>
             <div className='flex gap-2'>
               <Map size={20} className='text-accent' />
               <p>The device work with a subscription</p>
             </div>
             <div className='flex gap-2'>
               <Clock size={20} className='text-accent' />
               <p>Fast delivery</p>
             </div>
           </div>
         </div>
       
         </div>
   
        </div>
         
       </section>
      )
  }
  
  export default ProdDetail