import { Tracker } from '../interface/trackerInterface';
import { useShoppingCart } from 'use-shopping-cart';
import { FaPlus, FaMinus, FaX } from 'react-icons/fa6';


interface CartItemProps {
    item: Tracker;
  }
  
  const CartItem: React.FC<CartItemProps> = ({ item }) => {
    const { cartDetails, removeItem, incrementItem, decrementItem } = useShoppingCart();
    const quantity = cartDetails?.[item.id]?.quantity;

    return (
    <div className='flex w-full justify-between mb-4 h-[120px] border-b' >
        <div className='w-[50px] h-[110px] relative  mb-4  border-b'>

        <img src={item.img} className='object-contain' sizes='(max-width:110px) 110px, 110px' alt='' />
        </div>
        <div className='w-full max-w-[180px] flex flex-col justify-center gap-4'>
            <div className='flex items-center justify-between '>
                <h5>{item.name} </h5>
                <button onClick={()=>removeItem(item.id)}>
                    <FaX className='text-sm' />
                </button>
            </div>
            <div className='flex items-center justify-between'>
            <div className='flex gap-4'>
                <button onClick={()=>decrementItem(item.id)}>
                    <FaMinus className='text-[10px]' />
                </button>
                <div className='font-semibold '>{quantity}  </div>
                <button onClick={()=>incrementItem(item.id)}>
                    <FaPlus  className='text-[10px]'/>
                </button>
            </div>
            <div className='font-semibold text-balance text-right'>{item.currency}{item.price * (quantity || 0)}  </div>
            </div>
        </div>
        </div>
  )
}

export default CartItem