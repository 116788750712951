

const isProduction = process.env.NODE_ENV === 'production';

export const apiUrl = isProduction ? 'https://iot.dadaisma.com/' : 'http://localhost:3009/';
export const apiUrlF = isProduction ? 'https://iotfrontend-mu.vercel.app/' : 'http://localhost:3000/';


export const fetchProducts = async () => {
    try {
      const response = await fetch(`${apiUrl}products`);
      if (!response.ok) {
        throw new Error('Error: ' + response.status);
      }
      const data = await response.json();
      return data;
    } catch (error) {
        if (error instanceof Error) {
          throw new Error(error.message);
        } else {
          throw error;
        }
      }
    }