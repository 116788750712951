import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PopularTrackersCarousel from './PopularTrackersCarousel';
import { fetchProducts } from '../api/controllers';
import { Tracker } from '../interface/trackerInterface';

interface PopularProductsProps {
  data: Tracker[] | null;
  setData: React.Dispatch<React.SetStateAction<Tracker[] | null>>;
}

const PopularProducts: React.FC<PopularProductsProps> = ({ data, setData }) => {
  //const [data, setDataLocal] = useState<Tracker[] | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
   

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await fetchProducts();
            setData(data);
            setLoading(false);
          }  catch (error) {
            setError((error as Error).message);
            setLoading(false);
          }
        };
      
        fetchData();
      }, []);
  
    if (loading) return <div>Loading...</div>;
if (error) return <div>Error: {error}</div>;
  
     
  

  return (
    <section className='py-24 bg-slate-50'>
        <div className='container mx-auto'>
    <h2 className=' text-center'>Most Popular Trackers</h2>
    <p className='text-center mb-[30px]'>
        The most popular trackers on the market today.
    </p>
   </div>
        <PopularTrackersCarousel data={data} />

    <Link to="/allproducts">
    <button className='btn btn-accent mx-auto'>
        See all Trackers
    </button>
    </Link>
    
      
    </section>
  )
  }
  
  export default PopularProducts
    