import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import { Tracker } from "./interface/trackerInterface";
import ProdDetail from "./components/ProdDetail";
import PopularProducts from "./components/PopularProducts";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { CartProvider } from "use-shopping-cart";
import { stripePublicKey } from "./utils/util";
import { Toaster } from "./@/components/ui/toaster";
import { apiUrlF } from "./api/controllers";
import Success from "./pages/stripe/success";
import Error from "./pages/stripe/error";
import Hero from "./components/Hero";
import AllProducts from "./components/AllProducts";

const App = () => {
  const [data, setData] = useState<Tracker[] | null>(null);
  console.log(data)
  return (
    <>
      <CartProvider
        mode="payment"
        cartMode="client-only"
        stripe={stripePublicKey}
        successUrl={`${apiUrlF}stripe/success`}
        cancelUrl={`${apiUrlF}stripe/error`}
        language="en-US"
        currency="MXN"
        billingAddressCollection={true}
        shouldPersist={true}
        
      >
        <Header />
        {/*<div className="h-[2000px]">*/}
          <Routes>
            
            <Route path="/proddetail/:title" element={<ProdDetail />} />
            <Route path="/" element={
            <>
            <Hero />
            <PopularProducts data={data} setData={setData}  />
             </>
            } />
            <Route path="/allproducts" element={<AllProducts data={data}  />} />
            <Route path="/stripe/success" element={<Success />} />
            <Route path="/stripe/error" element={<Error />} />
          </Routes>
          <Toaster />
          <Footer />
       {/* </div> */}
      </CartProvider>
    </>
  );
};

export default App;
