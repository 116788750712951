import React from 'react'
import {useState, useEffect} from 'react';
import { Tracker } from '../interface/trackerInterface';
import ProductCategories from './ProductCategories';
import { fetchProducts } from '../api/controllers';

interface AllProductsProps {
  data: Tracker[] | null;
}

const AllProducts: React.FC<AllProductsProps> = () =>{ 
    const [data, setData] = useState<Tracker[] | null>(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
   

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await fetchProducts();
            setData(data);
            setLoading(false);
          }  catch (error) {
            setError((error as Error).message);
            setLoading(false);
          }
        };
      
        fetchData();
      }, []);


    
    return (
      <div>
        <ProductCategories data={data} />
      {data?.map((tracker: Tracker, index: number) => (
        <div key={index}>
          {/* Display properties of the tracker here */}
         
        </div>
      ))}
    </div>
  )
}

export default AllProducts