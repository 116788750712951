import React from "react";
import { Link } from "react-router-dom";
import { CgEye, CgShoppingBag } from "react-icons/cg";
import {
  Tracker as TrackerInterface,
  Network,
} from "../interface/trackerInterface";
import AddToCartBtn from "./AddToCartBtn";

interface TrackerComponentProps {
  tracker: TrackerInterface;
}

const Tracker: React.FC<TrackerComponentProps> = ({ tracker }) => {
  return (
    <div className="group">
      <div className="border h-[328] mb-5 p-4 overflow-hidden relative">
        <div className="bg-slate-200 w-full h-full group-hover:bg-slate-300 transition-all duration-300 flex justify-center items-center p-4">
        {tracker.popular && (
        <div className="absolute top-8 left-8 bg-accent text-white px-3 text-sm uppercase font-medium">
        Popular
        </div>
)}

          <img
            src={tracker.img}
            alt={tracker.title}
            className="w-[240px] h-[200px] object-contain"
          />

          <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center gap-[10px] opacity-0 group-hover:opacity-100 transition-all duration-300">
            <AddToCartBtn
              stripeproductid={tracker.stripeproductid}
              id={tracker.id}
              _id={tracker._id}
              title={tracker.title}
              description={tracker.description}
              img={tracker.img}
              price={tracker.price}
              btnStyles="btn-icon-centeredproduct btn-accent"
              icon={<CgShoppingBag />}
              text={"Buy"}
              currency="MXN"
            />

            <Link to={`/proddetail/${tracker.title}`}>
              <button className="btn-icon-centeredproduct btn-primary rounded-lg">
                <CgEye />
              </button>
            </Link>
          </div>
        </div>
      </div>
      <div className="flex gap-2">
        {tracker.network.map((network, index) => (
          <p key={index} className="font-semibold mb-2 text-gray-400">
            {network as unknown as string}
          </p>
        ))}
      </div>
      <h4 className="mb-1">{tracker.title} </h4>
      <div className="text-lg font-bold text-accent">${tracker.price}</div>
    </div>
  );
};

export default Tracker;
