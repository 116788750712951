import { useShoppingCart } from "use-shopping-cart"


const CheckoutBtn = () => {
  const { redirectToCheckout, clearCart, cartDetails } = useShoppingCart()

  const handleCheckout = async () => {
    try {
      console.log('ciiiiao')
      const res = await redirectToCheckout();
      clearCart();
      if(res?.error) {
        console.log(res.error.message)
        clearCart();
        console.log('ciao')
      } else {
        clearCart();
        console.log('ciiiiao')
        console.log(cartDetails)
      }
    } catch (error) {
      console.log(error)
    }
  }


 

  return (
    <button className="btn btn-primary w-full" onClick={handleCheckout}>Proceed to checkout</button>
  )
}

export default CheckoutBtn