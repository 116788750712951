import React from 'react'
import { useState, useEffect } from 'react';
import { Tracker, Category, Network, Item } from '../interface/trackerInterface';
import { RadioGroupItem, RadioGroup } from '../@/components/ui/radio-group';
import { Label } from '../@/components/ui/label';
import { Slider } from '../@/components/ui/slider';
import { fetchProducts } from '../api/controllers';
import Product from './Tracker';

type ProductCategoriesProps = {
    data: Tracker[] | null;
  };



const ProductCategories: React.FC<ProductCategoriesProps> = ({ data }) => {
   
    const [selectedCategory, setSelectedCategory] = useState<string>('all');
    const [selectedPrice, setSelectedPrice] = useState<number>(900);
    const [filteredProduct, setFilteredProduct] = useState<Tracker[] | null>(null); 
     
  
    useEffect(() => {
        if (data) {
          if (selectedCategory === 'all') {
            setFilteredProduct(data.filter(item => item.price <= selectedPrice));
          } else {
            const filteredP = data.filter(item => item.category === selectedCategory && item.price <= selectedPrice);
            setFilteredProduct(filteredP);
          }
        }
      }, [selectedCategory, data, selectedPrice]);   

  
  
  return (
    <section className='min-h-[120] py-10'>
        <div className="container mx-auto ">
        <div className='flex flex-col'>
            <aside className=' w-full p-4 mb-8 '>
                <RadioGroup defaultValue='all' className='flex flex-col gap-6 mb-12 xl:flex-row'>
                    <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='all' id='all' onClick={()=>setSelectedCategory('all')} />
                        <label htmlFor='all'>All</label>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='tracker' id='tracker' onClick={()=>setSelectedCategory('tracker')}/>
                        <label htmlFor='tracker'>Trackers</label>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='temperature' id='temperature' onClick={()=>setSelectedCategory('temperature')}/>
                        <label htmlFor='temperature'>Temperature</label>
                    </div>
                  
                    <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='watch' id='watch' onClick={()=>setSelectedCategory('watch')} />
                        <label htmlFor='watch'>Watch</label>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <RadioGroupItem value='pet' id='pet' onClick={()=>setSelectedCategory('pet')} />
                        <label htmlFor='pet'>Pet</label>
                    </div>
                </RadioGroup>
                <div className='mb-12'>
                 <div className='max-w-56'>
                    <div className='text-lg mb-4 font-medium'>
                    Max Price: <span className='text-accent font-semibold ml-2'>${selectedPrice} </span>
                    <span className='ml-2'>
                    {filteredProduct && filteredProduct.length > 1
                    ? `${filteredProduct.length} products`
                    : filteredProduct && filteredProduct.length === 0
                    ? `${filteredProduct.length} products`
                    : filteredProduct && `${filteredProduct.length} product`
                    }
                    </span>
                    </div>
                    <Slider defaultValue={[900]} max={1000} step={1} onValueChange={(val) => setSelectedPrice(val[0])} />
                 </div>
  
               </div>
                </aside>
            <div className=' w-full xl:w-[1050px] '>
                <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[30px] '>

                {filteredProduct?.map(data =>{
                    return <Product tracker={data} key={data.stripeproductid} />
                }) }
                </div>
                </div>
        </div>
        </div>

    </section>
  )
}

export default ProductCategories