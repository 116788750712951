import { ScrollArea } from "../@/components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../@/components/ui/sheet";
import { useState } from "react";
import { useShoppingCart } from "use-shopping-cart";
import CartItem from "./CartItem";
import { Tracker } from "../interface/trackerInterface";
import CheckoutBtn from "./CheckoutBtn";

const CartSidebar = () => {
  const {
    cartCount,
    cartDetails,
    shouldDisplayCart,
    handleCartClick,
    totalPrice,
  } = useShoppingCart();

  return (
    <Sheet open={shouldDisplayCart} onOpenChange={handleCartClick}>
      <SheetContent className="flex flex-col justify-between">
        <SheetHeader>
          
          <SheetTitle className="text-left ">
            My Shooping Cart ({cartCount})
          </SheetTitle>
        </SheetHeader>
        <>
        
          {cartCount === 0 ? (
            <div className="flex flex-col justify-center items-center w-full h-[760px]">
              <h5 className="text-black/50">Your cart is empty</h5>
            </div>
          ) : (
            <ScrollArea className="h-[70vh] xl:h-[74vh] pr-4  ">
              {cartDetails &&
                Object.entries(cartDetails).map(([key, item]) => {
                  const tracker: Tracker = {
                    title: item.title || "",
                    img: item.img || "",
                    network: item.network || "",
                    popular: item.popular || false,
                    id: item.id || "",
                    description: item.description || "",
                    name: item.name || "",
                    price: item.price || 0,
                    currency: "MXN" || "",
                    _id: item._id,
                    stripeproductid: item.stripeproductid || "",
                    category:item.category || ""

                    // include other properties from item here
                  };
                  return <CartItem item={tracker} key={key} />;
                })}
            </ScrollArea>
          )}
          
        </>
        {(cartCount || 0) > 0 && (
          <div className="flex flex-col ">
            <div className="flex justify-between font-semibold">
              <div className="uppercase mb-5">Total</div>
              <div>${totalPrice}</div>

            </div>
            <div className="mb-10">
            <CheckoutBtn />
            </div>
          </div>
        )}
      </SheetContent>
    </Sheet>
  );
};

export default CartSidebar;
export {};
