import { Link } from "react-router-dom"
import Nav from "./Nav"
import CartSidebar from "./CartSidebar"
import {CgShoppingBag} from 'react-icons/cg'
import { useShoppingCart } from "use-shopping-cart"

const Header = () => {
  const { cartCount, handleCartClick } = useShoppingCart()
  return (
    <header className="bg-white shadow-lg sticky top-0 py-8 z-40">
    <div className="container mx-auto flex justify-between items-center">
      <Link to='' className="hover:text-current">
      <h1 className="text-[26px]">
        <span className="text-accent">io</span>trakk </h1>
      </Link>
      <div className="flex items-center gap-[26px]">
      <Nav containerStyles='flex gap-[36px]' />
      <div className="relative cursor-pointer" onClick={handleCartClick}>
        <CgShoppingBag className="text-[36px]" />
        <div className="flex bg-accent w-[18px] h-[18px] absolute right-1 bottom-1 rounded-full text-white items-center justify-center text-sm font-medium">
         {cartCount}
          </div>
      </div>
      </div>
      <CartSidebar />
    </div>
    </header>
  )
}

export default Header