
import TrackerComponent from './Tracker';
import React from 'react'
import {Swiper, SwiperSlide, } from 'swiper/react';
import { Autoplay, Navigation }from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import {Pagination} from 'swiper/modules';
import { Tracker, Network } from '../interface/trackerInterface';




interface PopularTrackersCarouselProps {
  data: Tracker[] | null; 
  }
  

  
  const PopularTrackersCarousel: React.FC<PopularTrackersCarouselProps> = ({data}) => {
    const onlyPopular = data?.filter((tracker: Tracker) => tracker.popular);
  return (
    <>
    <div className='flex justify-between items-center gap-2 ml-4 mr-4'>
    
    <Swiper loop={true}
    modules={[Autoplay, Navigation, Pagination]}
    className='popular-tracker-slider mb-8'
    autoplay={{ delay: 3000 }}  speed={1000} 
    navigation={{
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    }}
    slidesPerView={1}
    spaceBetween={30}
    breakpoints={
      {
        640: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 50
        }
      }
    }
    pagination={{
      clickable:true,
    }}
    >
           
         {onlyPopular ? onlyPopular.map((tracker: Tracker) => (
      <SwiperSlide key={tracker.id}>
        <TrackerComponent tracker={tracker} />
      </SwiperSlide>
    )) : null}
    </Swiper>
    

    
   
    </div>
    </>
  )
}

export default PopularTrackersCarousel