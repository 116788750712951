import { Link } from "react-router-dom";
import { Input } from "../@/components/ui/input";
import {  
  RiFacebookFill,
  RiTwitterFill,
  RiInstagramFill,
  RiYoutubeFill,
  RiWhatsappFill
} from 'react-icons/ri';

const Footer = () => {
  return (
    <footer className="pt-12 xl:pt-24 bg-primary text-white text-center">
      <div className="container mx-auto">
        <div className="flex flex-col gap-8">
          <h2 className="capitalize leading-tight mb-2">
            Subscribe to our newsletter
          </h2>
          <p className="text-white/60">Be the first to get the latest news about trends, promotions and much more</p>
        </div>
        <form className="flex flex-col xl:flex-row w-full max-w-[600px] mx-auto justify-center items-center gap-1 mt-4">
          <Input placeholder="Your email address" className="bg-white/80 h-[40px]" />
          <button className="btn w-full xl:max-w-[150px] h-[40px] mt-4 btn-accent mb-4">Join</button>
        </form>
        <div className="flex gap-8 mx-auto text-[20px] text-white/60 mb-20 justify-evenly sm:justify-center ">
          <Link to=''>
          <RiWhatsappFill />
          </Link>
          <Link to=''>
          <RiYoutubeFill />
          </Link>
          <Link to=''>
          <RiInstagramFill />
          </Link>
          <Link to=''>
          <RiTwitterFill />
          </Link>
          <Link to=''>
          <RiFacebookFill />
          </Link>
        </div>
      </div>
      <div className="py-6 border-t border-white/5 text-white/60 border-none">
        Copyright &copy; 2024 IOTrakk. All rights reserved.
      </div>
    </footer>
  )
}

export default Footer